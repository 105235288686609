import React from "react";
import { Helmet } from "react-helmet";

function SEO({
  title = "Swiss Fire Protection Research and Development AG",
  description = "Research, evaluation, and improvement of industrial fire prevention, protection and emergency systems",
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://sfprd.com/" />
      <meta
        property="og:site_name"
        content="Swiss Fire Protection Research and Development AG"
      />
      <meta property="og:image" content="/logo.svg" />
      <meta property="og:description" content={description} />
      <meta property="twitter:card" content="summary_large_image"></meta>
      <meta property="twitter:title" content={title} />
      <link
        rel="icon"
        href="/cropped-SFPRD-Logo_ogo-512px-192x192.png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon-precomposed"
        href="/cropped-SFPRD-Logo_ogo-512px-180x180.png"
      />
      <link rel="shortlink" href="https://sfprd.com/" />
    </Helmet>
  );
}

export default SEO;
