import { Link } from "gatsby";
import React, { useState } from "react";
import logo from "../images/logo-icon.svg";
import MenuOverlay from "./MenuOverlay";
import "../styles/menu.css";

const navigation = [
  { text: "Focus Research", link: "/" },
  { text: "Causes of Tank Fires", link: "/causes-of-tank-fires" },
  { text: "Our mission", link: "/mission" },
  { text: "Contact", link: "/contact" },
];

function Menu() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar__img__container">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <div>
          <div className="nav__text">
            <p>Swiss Fire Protection</p>
            <p>Research & Development AG</p>
          </div>
        </div>

        <div className="hamburger" onClick={() => setOpen((prev) => !prev)}>
          <div
            className={`hamburger__line hamburger__line__1 ${
              open ? "active" : ""
            }`}
          />
          <div
            className={`hamburger__line hamburger__line__2 ${
              open ? "active" : ""
            }`}
          />
          <div
            className={`hamburger__line hamburger__line__3 ${
              open ? "active" : ""
            }`}
          />
        </div>
      </nav>
      <MenuOverlay
        visible={open}
        setVisible={setOpen}
        navigation={navigation}
      />
    </>
  );
}

export default Menu;
