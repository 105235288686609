import React, { useCallback } from "react";
import { Link } from "gatsby";

function MenuOverlay({ visible, setVisible, navigation }) {
  const click = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return (
    <div className={`navbar__overlay ${visible ? "active" : ""}`}>
      {navigation.map((nav, i) => {
        return (
          <Link
            className="navbar__link"
            to={nav.link}
            key={i}
            onClick={click}
            style={{ color: nav.color ? nav.color : "#e10707" }}
          >
            {nav.text}
          </Link>
        );
      })}
    </div>
  );
}

export default MenuOverlay;
